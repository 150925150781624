import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Home.css';

const Home = () => {
  const [slogan, setSlogan] = useState('');
  const [buttonColor, setButtonColor] = useState('#007bff');
  const projectSlogan = 'Elevating Art, Empowering Creators';
  const navigate = useNavigate();

  const updateSlogan = useCallback(() => {
    if (slogan.length < projectSlogan.length) {
      setSlogan(prevSlogan => projectSlogan.slice(0, prevSlogan.length + 1));
    }
  }, [slogan, projectSlogan]);

  useEffect(() => {
    const sloganInterval = setInterval(updateSlogan, 100);
    return () => clearInterval(sloganInterval);
  }, [updateSlogan]);

  const generateRandomColor = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  };

  useEffect(() => {
    const colorInterval = setInterval(() => {
      setButtonColor(generateRandomColor());
    }, 1000);
    return () => clearInterval(colorInterval);
  }, []);

  const redirectToPresentationPage = () => {
    navigate('/PresentationPage');
  };

  return (
    <div className="home-page" role="main">
      <div className="centered">
        <h1 className="project-name" aria-label="Caveira Hippie">Caveira Hippie</h1>
        <h2 className="slogan" aria-live="polite">{slogan}</h2>
        <button
          className="start-button"
          onClick={redirectToPresentationPage}
          style={{ backgroundColor: buttonColor }}
          aria-label="Start the presentation"
        >
          Get Started
        </button>
      </div>
      <footer className="footer" role="contentinfo">
        <p>
          Powered by{' '}
          <a href="https://www.softdrakula.com" className="softdrakula-link" target="_blank" rel="noopener noreferrer" aria-label="Visit SoftDrakula website">
            Soft<span className="orange-letter">D</span>rakula
          </a>. &copy;
        </p>
      </footer>
    </div>
  );
};

export default Home;
