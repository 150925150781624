// src/Routes.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'; // Adjust path if needed
import PresentationPage from './pages/PresentationPage'; // Adjust path if needed
import Web3Page from './pages/Web3Page';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/PresentationPage" element={<PresentationPage />} />
        <Route path="/Web3Page" element={<Web3Page/>} />
        {/* Add other routes here as needed */}
      </Routes>
    </Router>
  );
};

export default AppRoutes;

