import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Web3Page.css';

const Web3Page = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="web3-page">
      <div className="banner">
        <h1>News coming soon</h1>
        <p>
          Get ready for an immersive experience as we unveil the next generation of the internet. Web3 is set to revolutionize how we interact online, with groundbreaking advancements in decentralization, blockchain, and user empowerment. Stay tuned!
        </p>
      </div>
      <button className="home-button" onClick={handleGoHome}>Home</button>
    </div>
  );
};

export default Web3Page;
