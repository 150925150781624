import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/PresentationPage.css';

const PresentationPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [titleIndex, setTitleIndex] = useState(0);
  const [titlePosition, setTitlePosition] = useState({});
  const [showExploreButton, setShowExploreButton] = useState(false);
  const navigate = useNavigate();

  const titles = [
    "Welcome to Caveira Hippie",
    "Welkom bij Caveira Hippie",
    "Bienvenido a Caveira Hippie",
    "Benvenuti a Caveira Hippie",
    "Bem-vindo(a) a Caveira Hippie",
    "欢迎来到 Caveira Hippie",
    "Bienvenue chez Caveira Hippie",
    "Qapla'! jIH 'ej Caveira Hippie",
    "Bonvenon al Caveira Hippie",
    "مرحبًا بك في Caveira Hippie",
    "Добро пожаловать в Caveira Hippie",
    "Witaj w Caveira Hippie",
    "Willkommen bei Caveira Hippie",
    "Velkommen til Caveira Hippie"
  ];

  const positions = [
    { top: '10%', left: '10%' },
    { top: '20%', left: '20%' },
    { top: '30%', left: '30%' },
    { top: '40%', left: '40%' },
    { top: '50%', left: '50%' },
    { top: '60%', left: '60%' },
    { top: '70%', left: '70%' }
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTitleIndex(prevIndex => (prevIndex + 1) % titles.length);
      setTitlePosition(positions[Math.floor(Math.random() * positions.length)]);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const pages = document.querySelectorAll('.page');
    pages.forEach((page, index) => {
      if (index === currentPage) {
        const paragraphs = page.querySelectorAll('p');
        paragraphs.forEach((paragraph) => {
          const text = paragraph.innerText.trim();
          paragraph.innerHTML = '';
          let charIndex = 0;
          const intervalId = setInterval(() => {
            if (charIndex < text.length) {
              paragraph.textContent += text[charIndex];
              charIndex++;
            } else {
              clearInterval(intervalId);
              if (currentPage === 5 && paragraph === paragraphs[paragraphs.length - 1]) {
                setTimeout(() => {
                  setShowExploreButton(true);
                }, 3000);
              }
            }
          }, 33);
        });
      }
    });
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage === 5) {
      navigate('/Web3Page');
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoToWeb3Page = () => {
    navigate('/Web3Page');
  };

  return (
    <div className="presentation-page">
      <div className="book-container">
        <div className={`page ${currentPage === 0 ? 'active' : ''}`}>
          <h1 style={titlePosition} aria-live="polite">{titles[titleIndex]}</h1>
          <h2 className="glowing-text">Elevating Art, Empowering Creators</h2>
          <p>
            The evolution of social networks is undergoing a dramatic shift with the advent of Web3. Web1 started with static, limited interaction, laying the early groundwork for the digital world. Web2 then expanded this with dynamic, user-generated content and social interactivity. Now, Web3 takes this evolution further by introducing decentralized networks and enhanced user control, promising a more immersive and equitable online experience.
          </p>
        </div>
        <div className={`page ${currentPage === 1 ? 'active' : ''}`}>
          <p>
            Web2 brought us social media, online gaming, and essential services, but also increased data collection, making personal information a valuable asset. The phrase "If the service is free, you are the product" highlights how user data fuels tech giants.

            In the Web3 era, we see a shift towards decentralized networks that enhance privacy and user control. Beyond NFTs, Web3 offers smart contracts, tokenization, and greater interoperability, creating a more secure, equitable, and user-centric internet. This new landscape values both creativity and personal data, promising a transformative digital experience.
          </p>
        </div>
        <div className={`page ${currentPage === 2 ? 'active' : ''}`}>
          <p>
            As we enter the Web3 era, the digital world is transforming with decentralization at its core. Blockchain technology ensures secure, transparent transactions, while AI enhances everyday experiences, from personalized content to smart assistants. Web3 introduces exciting innovations like decentralized finance (DeFi) and digital identities, all secured by blockchain. Decentralized autonomous organizations (DAOs) are also redefining governance. This new phase promises a more secure, transparent, and user-empowered digital ecosystem.
          </p>
        </div>
        <div className={`page ${currentPage === 3 ? 'active' : ''}`}>
          <p>
            Web3 and AI are not just buzzwords; they herald a profound transformation in digital interaction. These technologies are set to blend the digital and physical worlds, offering seamless, intuitive experiences. Web3, powered by blockchain, promises transparency, security, and true digital ownership, while AI enhances personalization and anticipates user needs. Together, they enable innovations like augmented and virtual reality, transforming industries such as healthcare, finance, and education. As we enter this new internet chapter, users gain greater empowerment, control, and opportunities for innovation, shaping a more equitable digital future.
          </p>
        </div>
        <div className={`page ${currentPage === 4 ? 'active' : ''}`}>
          <p>
            As we navigate these changes, it's crucial to remain informed and vigilant about how our data is used and protected. The future holds exciting possibilities, but it also calls for a thoughtful approach to privacy and security. The era of Web3 is more than just a technological upgrade; it's an opportunity to redefine our relationship with the digital world.
          </p>
        </div>
        <div className={`page ${currentPage === 5 ? 'active' : ''}`}>
          <h3 className="glowing-text">Welcome to Caveira Hippie - Elevating Art, Empowering Creators.</h3>
          <p>
            Where innovation knows no bounds and creativity thrives in every pixel.
            Caveira Hippie isn't just a platform—it's a movement exploring the transformative potential of Web3. We provide the latest insights into Web3 and AI, with a mission that goes beyond information. We aim to elevate art and empower creators in the digital age.
          </p>
          <button 
            className={`end-presentation-button ${showExploreButton ? 'show' : ''}`} 
            onClick={handleGoToWeb3Page}
            aria-label="Explore Web3 page"
          >
            Explore Web3
          </button>
        </div>
      </div>
      <div className="navigation-buttons">
        <button onClick={handlePreviousPage} disabled={currentPage === 0} aria-label="Previous page">Previous</button>
        <button onClick={handleNextPage} disabled={currentPage === 5} aria-label="Next page">Next</button>
      </div>
      <button className="back-button" onClick={handleGoBack} aria-label="Go back">Back</button>
    </div>
  );
};

export default PresentationPage;
